import React, { useEffect } from 'react';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className='home'>
        <div className='container'>
          <h1>Westfield Global Construction Company</h1>
          <span>Crafting Quality, Constructing Excellence</span>
        </div>
      </div>

      <div className='body'>
        <div className='stats'>
          {/* */}
          <div className='card'>
            <h1>20+</h1>
            <h5>Skilled Contractors</h5>
            <p>
              Our team includes experienced architects, engineers, and
              craftsmen.
            </p>
          </div>
          {/* */}
          <div className='card'>
            <h1>580+</h1>
            <h5>Projects</h5>
            <p>
              We have been delivering quality construction services since 2010.
            </p>
          </div>
          {/* */}
          <div className='card'>
            <h1>100%</h1>
            <h5>Customer Satisfaction</h5>
            <p>
              Our clients trust us to bring their visions to life, and we exceed
              their expectations every time.
            </p>
          </div>
        </div>

        {/*About The Company */}
        <div className='about'>
          <div className='card'>
            <img src={process.env.PUBLIC_URL + '/images/2.jpg'} alt='#' />
            <h1>About Us</h1>
            <p>
              {' '}
              Westfield Global Construction is an architectural / building
              consultancy and planning firm structured to provide a diversified
              scope of services in the Architecture, Engineering and
              Construction industry.
              <br/> Since inception in 2010, Westfield Global
              Construction Ltd has provided high quality building construction
              services and is recognized as one of the area’s most experienced
              builders. <br /> Our target clientele includes, and is not
              restricted to, private, public, industrial, commercial,
              institutional, recreational and tourism, educational and
              governmental parastatal. <br /> Our Team consists of highly
              seasoned, enthusiastic and dedicated professionals who are focused
              on project delivery and multi-disciplinary consultancy practice as
              a consortium which provides a single source point for services{' '}
              <br />
            </p>
            <button onClick={() => navigate('/about')}>
              Learn more <ArrowForward />{' '}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

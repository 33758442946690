import React, { useState, useEffect } from 'react';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [formdata, setFormdata] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    fetch('https://formspree.io/f/meqbygko', {
      method: 'POST',
      body: new FormData(form),
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (response.ok) {
          alert('Form submitted successfully!');
          setFormdata({
            name: '',
            email: '',
            message: '',
          });
        } else {
          response.json().then((data) => {
            if (Object.hasOwn(data, 'errors')) {
              alert(data['errors'].map((error) => error['message']).join(', '));
            } else {
              alert('Oops! There was a problem submitting your form');
            }
          });
        }
      })
      .catch((error) => {
        alert('Oops! There was a problem submitting your form');
      });
  };

  const handleChange = (e) => {
    setFormdata({
      ...formdata,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className='contact-container'>
      <div className='contact'>
        <div className='caption'>
          <h1>Contact Us</h1>
        </div>
        <p>
          For further questions, including partnership opportunities, please
          email hello@westfield.com or contact using our contact form.
        </p>
        <form name='contact' onSubmit={handleSubmit}>
          <input type='hidden' name='form-name' value='contact' />
          <div className='form-group'>
            <label htmlFor='name'>Name:</label>
            <input
              type='text'
              name='name'
              value={formdata.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className='form-group'>
            <label htmlFor='email'>Email:</label>
            <input
              type='email'
              name='email'
              value={formdata.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className='form-group'>
            <label htmlFor='message'>Message:</label>
            <textarea
              name='message'
              value={formdata.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className='form-group'>
            <button type='submit'>SEND MESSAGE</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;

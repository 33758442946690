import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Services = () => {
  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Thank you!');
    navigate('/');
  };

  return (
    <div>
      {' '}
      <div className='construction-container'>
        <h1>Site Under Construction</h1>
        <p>
          We're working hard to finish the development of this site. Our target
          launch date is
          <strong> January 2024</strong>. Sign up below to receive updates and
          be the first to know when we go live!
        </p>
        <form onSubmit={handleSubmit}>
          <input type='email' placeholder='Enter your email' required />
          <button type='submit'>Notify Me!</button>
        </form>
      </div>
    </div>
  );
};

export default Services;

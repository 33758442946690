import React from 'react';

const Footer = () => {
  return (
    <div>
      <footer className='footer'>
        <div className='footer-logo'>
          <img
            src={process.env.PUBLIC_URL + '/images/westfield logo.png'}
            alt='Company Logo'
          />
        </div>
        <div className='copyright'>
          <a href='mailto:info@westfieldglobal.ng' alt='#'>
            info@westfieldglobal.ng
          </a>
          <a href='tel:+2347033587150'>+2347033587150</a>
          <p>6, Ijebu-Ode Close, Area 2, Garki Abuja.</p>
          <p>&copy; 2023 Westfield G C L. All Rights Reserved</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

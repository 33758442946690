import React, { useEffect } from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PeopleIcon from '@mui/icons-material/People';
// import { useNavigate } from 'react-router-dom';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='about'>
      <div className='about-header'>
        <div className='about-header__left'>
          <img src={process.env.PUBLIC_URL + '/images/6.jpg'} alt='Image_' />
        </div>

        <div className='about-header__right'>
          <p>
            Since inception in 2010, Westfield Global Construction Ltd has
            provided high quality building construction services and is
            recognized as one of the area’s most experienced builders.
          </p>
          <div className='about-header__right1'>
            <AssignmentIcon className='icon' />{' '}
            <div className='icon-text'>
              <h2>CLIENTS</h2>
              <p>
                Our target clientele includes, and is not restricted to,
                private, public, industrial, commercial, institutional,
                recreational and tourism, educational and governmental
                parastatal.
              </p>
            </div>
          </div>
          <div className='about-header__right2'>
            <PeopleIcon className='icon' />{' '}
            <div className='icon-text'>
              <h2>PROFESSIONAL TEAM</h2>
              <p>
                Our Team consists of highly seasoned, enthusiastic and dedicated
                professionals who are focused on project delivery and
                multi-disciplinary consultancy practice as a consortium which
                provides a single source point for services
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='team'>
        <div className='layer'>
          <h2>OUR TEAM</h2>
          <p>
            At Westfield Global Construction Ltd we have an energetic and highly
            motivated team who enjoy completing projects to the highest of
            industry standards.
          </p>
          <p>
            Currently, we have approximately 35 staff on our team with a wide
            range of experience and skills to ensure we can successfully deliver
            on any project. A very high average employment duration of 7.25
            years signifies the devotion, reliability, experience and commitment
            that customers can expect us to provide on every job we undertake.
          </p>
          <p>
            At Westfield we fully believe the adage “Your staff are your most
            important asset”. We realize that the personal and professional
            development of our team is imperative in ensuring the successful
            delivery of projects, and thus the success of our business. We
            therefore invest in our staff through comprehensive training
            programs, mentoring and coaching. The rewards of this philosophy are
            inevitably shared with customers through increased satisfaction and
            better-quality service.
          </p>
          <p>
            Our administrative office is based in Abuja whilst our operations
            are centered across the country. Confining the majority of our
            business to every part of the country ensures that clients get local
            contractors with local knowledge and expertise.
          </p>
        </div>
      </div>
      <div className='history'>
        <h2>OUR HISTORY</h2>
        <p>
          From humble beginnings, Westfield Global Construction Ltd has expanded
          over time to be the established major player in the construction
          industry that we see today. Despite 7 years of successful operation in
          the building industry. The continuing desire for quality and
          professional service was reaffirmed with this change. Since 2010,
          Westfield Global Construction Ltd has gone from strength to strength,
          taking on larger and more ambitious projects. Project and contract
          management systems have been re-engineered to guarantee customer
          satisfaction. Westfield Global Construction Ltd achieved QEST
          accreditation in 2010 and also updated their Health and Safety,
          Environmental and Quality policies and procedures. With a long and
          intimate history, it is no wonder the culture at Westfield Global
          Construction Ltd is one of continual improvement. With this “can-do”
          attitude we, at Westfield Global Construction Ltd, are excited to
          confront the challenges and seize the opportunities the future will
          bring.
        </p>
      </div>
    </div>
  );
};

export default About;

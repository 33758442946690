import React, { useState, useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';

const Navbar = () => {
   const [isSticky, setIsSticky] = useState(false);
   const location = useLocation();
   const offset = 100; // scroll offset

   useEffect(() => {
     const handleScroll = () => {
       setIsSticky(window.scrollY > offset);
     };

     window.addEventListener('scroll', handleScroll);

     return () => {
       window.removeEventListener('scroll', handleScroll);
     };
   }, [offset]);

  return (
    <nav className={isSticky ? 'sticky' : 'navbar'}>
      <div className='navbar-brand'>Westfield G C L</div>
      
      <div className='navbar-links'>
        {location.pathname !== '/' && <NavLink to='/' className={({ isActive }) => isActive ? 'active-link' : ''}>Home</NavLink>}
        <NavLink to='/about' className={({ isActive }) => isActive ? 'active-link' : ''}>About Us</NavLink>
        <NavLink to='/services' className={({ isActive }) => isActive ? 'active-link' : ''}>Services</NavLink>
        <NavLink to='/contact' className={({ isActive }) => isActive ? 'active-link' : ''}>Contact Us</NavLink>
      </div>
    </nav>
  );
}

export default Navbar